import Footer from "./components/Footer";
import "./App.css";
import Home from "./components/Home";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
  Redirect,
} from "react-router-dom";
import SMS from "./components/SMS";
import ShortCodes from "./components/ShortCodes";

function App() {
  return (
    <div className="App w-100 h-100">
      <BrowserRouter>
        <Routes>
           <Route path={"/"} element={<Home/>}></Route>
           <Route path={"/sms"} element={<SMS/>}></Route>
           <Route path={"/short-codes"} element={<ShortCodes/>}></Route>
        </Routes>
      </BrowserRouter>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
