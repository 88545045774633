import {Link} from 'react-router-dom';
import React, { useEffect } from 'react';


const SMS = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

      return (
        <div>

        <div id="sticky-wraper is-sticky" className="sticky-wrapper" style={{height:"171px"}}>
            <nav className="navbar navbar-expand-lg" style={{width:"100%",position:"fixed",backgroundColor:"#FFB527"}}>                
                <div className="container">
                    <Link to="/" className="navbar-brand d-flex align-items-center">
                        <img src="images/logo.webp" className="navbar-brand-image img-fluid" alt="beyond logo"/>
                        <span className="navbar-brand-text">Beyond<small>VAS</small></span></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-lg-auto">
                            <li className="nav-item"><Link to="/" className="nav-link click-scroll" >Home</Link>
                            </li>        
                        </ul>
                        <div className="d-none d-lg-block ms-lg-3">
                            <a className="btn custom-btn custom-border-btn" 
                            style={{backgroundColor:"var(--custom-btn-bg-hover-color)", border:"none"}}
                            href="https://beyondsms.beyondintochsoftware.com/site/login" target="_blank" role="button" aria-controls="offcanvasExample"><span className="text-light">Login</span></a>
                        </div>
                        <div className="d-none d-lg-block ms-lg-3">
                            <a className="btn custom-btn custom-border-btn"
                            style={{backgroundColor:"var(--custom-btn-bg-hover-color)", border:"none"}}
                            
                            href="https://beyondsms.beyondintochsoftware.com/site/signup"
                            // href='#'
                             target="_blank" role="button" aria-controls="offcanvasExample"><span className="text-light">Register</span></a>
                        </div>
                    </div>
                </div>
            </nav>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
            fill='#FFB527'
              fill-opacity="1"
              d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
            ></path>
          </svg>
        </div>
        <div className="body sms_svg">
            <img src='./images/tech1.svg'
             style={{
                position:"absolute",
                top:'15%',
                left:"50%",
                height:"14cm"
             }}
             />
                 
        <section className="events-section " id="section_2" style={{marginTop:"4cm"}}>
        

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h2 className="" style={{marginTop:"4em"}}>Beyond SMS</h2>
                        </div>


                        <div className="col-lg-12 col-12  mb-lg-0">
                            <h3 className="text-muted">Captivate your customers with customized,<br/> precise communications tailored to their preferences <br/>and individual interests.</h3>
                        </div>

                        <div className="col-lg-12 col-12  mb-lg-0">
                            <a className="btn custom-btn custom-border-btn mt-3" href="https://beyondsms.beyondintochsoftware.com/site/signup"  target="_blank" role="button" aria-controls="offcanvasExample" 
                            style={{backgroundColor:"var(--custom-btn-bg-hover-color)", border:"none", marginBottom:"5cm", marginBottom:"6em"}}>
                                <span className="text-light h4 mt-5">Sign Up</span>
                            </a>
                        </div>


                     
                        <hr className="mt-5" style={{marginTop:"60cm"}}/>
                        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="card h-100 w-100">
                            <div class="card-body">
                            <img src='./images/broadcast.png' style={{ height:"80px",margin:"auto", boxShadow:"0 0 0 rgba(0,0,0,0.3)"}}
                                className='mb-3 d-flex mb-1 justify-content-center'/>
                            <h5 class="card-title"> <a href="event-detail.html" className="events-title">Broad Cast SMS</a></h5>
                            <p class="card-text">With features like scheduling and contacts file upload, you can maximize communication to all of your customers efficiently and conveniently.</p>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="card h-100 w-100">
                            <div class="card-body">
                            <img src='./images/exchange.png' style={{ height:"80px",margin:"auto"}}
                                className='mb-3 d-flex mb-1 justify-content-center'/>
                            <h5 class="card-title"> <a href="event-detail.html" className="events-title">Two Way SMS</a></h5>
                            <p class="card-text">Share information with you users or collect feedback, this is a great way to enrich customer engagement</p>
                            </div>
                          
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="card h-100 w-100">
                            <div class="card-body">
                            <img src='./images/card-payment.png' style={{ height:"80px",margin:"auto"}}
                                className='mb-3 d-flex mb-1 justify-content-center'/>
                            <h5 class="card-title"> <a href="event-detail.html" className="events-title">Transactional SMS</a></h5>
                            <p class="card-text">Transactional SMSs are delivered immediately they are sent, which makes them effective for notifications, reminders, two-factor authentication and more</p>
                            </div>
                          
                        </div>
                        </div>    
                        
                    </div>
                </div>
            </section>


        
                    
        <section className="events-section section-padding" id="features">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12 ">
                        <h2 className="" style={{color:"#FFB527"}} >Features</h2>
                    </div>

                    <div className='mb-5'>
                    <p className='h5 text-muted '> Are you looking for a reliable SMS system that offers discounted pricing, high availability, excellent deliverability, and seamless integration capabilities? Look no further! Our SMS system provides all these features and more, making it the perfect solution for your communication needs.
                    </p>
                    </div>


                   
    
                    <div className='row d-flex justify-content-start'>

                    <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="card h-100 w-100">
                            <div class="card-body">
                            <img src='./images/discounts.png' style={{ height:"80px",margin:"auto"}}
                                className='mb-3 d-flex mb-1 justify-content-center'/>
                            <h5 class="card-title"> <a href="event-detail.html" className="events-title">Discounted Pricing</a></h5>
                            <p class="card-text">We understand the importance of cost-effectiveness, which is why we offer discounted pricing plans tailored to suit your specific requirements. Whether you need to send a few hundred messages or millions of messages, we have flexible pricing options to fit your budget..</p>
                            </div>
                          
                        </div>
                        </div>   


                        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="card h-100 w-100">
                            <div class="card-body">
                            <img src='./images/available.png' style={{ height:"80px",margin:"auto"}}
                                className='mb-3 d-flex mb-1 justify-content-center'/>
                            <h5 class="card-title"> <a href="event-detail.html" className="events-title">High Availability</a></h5>
                            <p class="card-text">Our SMS system is built on a robust infrastructure that ensures high availability. You can rely on our platform to deliver your messages promptly, without any downtime or disruptions. We have redundant systems in place to guarantee continuous service, so you never have to worry about missed opportunities.</p>
                            </div>
                          
                        </div>
                        </div> 
                    
                        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="card h-100 w-100">
                            <div class="card-body">
                            <img src='./images/deliverable.png' style={{ height:"80px",margin:"auto"}}
                                className='mb-3 d-flex mb-1 justify-content-center'/>
                            <h5 class="card-title"> <a href="event-detail.html" className="events-title">Deliverability</a></h5>
                            <p class="card-text">We prioritize message deliverability to ensure that your SMS campaigns reach their intended recipients. Our platform is integrated with multiple leading telecom operators, allowing us to optimize delivery routes and maximize the chances of successful message transmission. With our system, you can expect high deliverability rates, minimizing the risk of undelivered messages..</p>
                            </div>
                          
                        </div>
                        </div> 


                        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="card h-100 w-100">
                            <div class="card-body">
                            <img src='./images/technical-support.png' style={{ height:"80px",margin:"auto"}}
                                className='mb-3 d-flex mb-1 justify-content-center'/>
                            <h5 class="card-title"> <a href="event-detail.html" className="events-title">Integration Support</a></h5>
                            <p class="card-text">Our SMS system is designed to seamlessly integrate with other systems, making it easier for you to incorporate SMS functionality into your existing workflows. We support major programming languages, including Java, Python, Ruby, PHP, and more. Whether you want to send SMS notifications from your website, mobile app, CRM, or any other system, our APIs and SDKs make integration a breeze</p>
                            </div>
                          
                        </div>
                        </div> 

                        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="card h-100 w-100">
                            <div class="card-body">
                            <img src='./images/customer-service.png' style={{ height:"80px",margin:"auto"}}
                                className='mb-3 d-flex mb-1 justify-content-center'/>
                            <h5 class="card-title"> <a href="event-detail.html" className="events-title">Reliable Customer Support</a></h5>
                            <p class="card-text">Don’t miss out on the benefits of an efficient, discounted, high availability, and deliverable SMS system with seamless integration capabilities. Contact us today to learn more about our offerings and how we can tailor our solution to meet your specific requirements. Empower your communication strategy with our reliable SMS system and reach your audience effectively.</p>
                            </div>
                          
                        </div>
                        </div> 
                    </div>
                    <p className='mt-5 h5 text-muted'>Don’t miss out on the benefits of an efficient, discounted, high availability, and deliverable SMS system with seamless integration capabilities. Contact us today to learn more about our offerings and how we can tailor our solution to meet your specific requirements. Empower your communication strategy with our reliable SMS system and reach your audience effectively.</p>
                </div>
            </div>
        </section>


        

        <section className="events-section  pb-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-5 mb-lg-0">
                            <div className="custom-block-info">
                                <h1 className="">Pricing</h1>
                            </div>
                            <div className="custom-block-info">
                                <h4 className="text-muted mb-5">All prices are exclusive of VAT. Prices are in Kenyan Shillings.</h4>
                            </div>
                        </div>

                        <div className="col-lg-12 justify-content-center">
                            <div className="">
                                <div class="">
                                    <div class="card-content table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Basic Plan <br/>KES 0-19,999</th>
                                                    <th scope="col">Plus Plan <br/>KES 20,000 - 99,999</th>
                                                    <th scope="col">Premium Plan <br/>KES 100,0000 - 399,999</th>
                                                    <th scope="col">Max Plan <br/>KES 400,0000+</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Safaricom</th>
                                                    <td>KES 0.7</td>
                                                    <td>KES 0.6</td>
                                                    <td>KES 0.5</td>
                                                    <td>KES 0.4</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Airtel Kenya(Local Traffic)</th>
                                                    <td>KES 0.7</td>
                                                    <td>KES 0.6</td>
                                                    <td>KES 0.5</td>
                                                    <td>KES 0.4</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Other Kenyan Telcos</th>
                                                    <td>KES 0.7</td>
                                                    <td>KES 0.6</td>
                                                    <td>KES 0.5</td>
                                                    <td>KES 0.4</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  );
};

export default SMS;
