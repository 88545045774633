import Navbar from "./Navbar";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope, faLocation, faMailBulk, faMap, faMapLocation, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram,faTwitter} from "@fortawesome/free-brands-svg-icons"
import emailjs from 'emailjs-com';
import {faUser} from "@fortawesome/free-solid-svg-icons";




const Home =()=>{
  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Add your email service configuration
    const serviceId = 'service_amag3uh';
    const templateId = 'template_7jl2198';
    const userId = '2zFpyN39uAc7MzBq5';

    
    // Get the form values
    const fullName = event.target['full-name'].value;
    const email = event.target.email.value;
    const message = event.target.message.value;

    // Prepare the email parameters
    const templateParams = {
      from_name: fullName,
      from_email: email,
      message: message,
    };

    // Send the email
    emailjs.send(serviceId, templateId, templateParams, userId)
    .then((response) => {
        console.log('Email sent successfully!', response);
        // Clear the form fields
        event.target.reset();
        // Show a success message to the user
        alert('Thank you for your message! We will get back to you soon.');
    })
    .catch((error) => {
        console.error('Error sending email:', error);
        // Show an error message to the user
        alert('An error occurred while sending the message. Please try again later.');
    });
   };


    return(
      <>
        <Navbar/>

        <main >
        <section  className="hero-section" id="hero-section" >
          <div className="container" >
            <div className="row">
              <div className="col-12 p-5" style={{marginTop:"3cm"}}>
                <h2 className="text-white pt-3"><span  style={{               
                  color:"#FFB527",marginTop:"",
                  fontSize:"1.6cm",        
                  lineHeight:"1cm",          
                  letterSpacing: "-0.03em",                  
                  fontFamily:"trueno"}}>
                  GROW YOUR 
                <br/>
                <span className='text-light'>BUSINESS</span>
                </span>
                </h2>
                
          
                <p className="text-white"
                style={{
                  letterSpacing: "-0.03em",            
                }}
                >
                  We develop intergrated turn-key mobile solutions that create positive impact on <br/>
                  bussinesses and our customers' lives
                </p>

                <div className='row p-3 ' style={{marginBottom:"2cm",marginTop:"1.5cm"}}>
                  <div className='col-md-6 col-sm-12 row'>
                    <input type='text' className='col-md-6  col-sm-12 p-1 px-2' placeholder='Phone Number/Email Address'
                      style={{ fontStyle: 'italic',background:"#f0f0f0",border:"none"}}
                    />
                    <a className="px-4 col-md-3 col-sm-12 text-center" style={{ backgroundColor: "#FFB527", border: "none", fontFamily:"trueno" }} href="https://beyondsms.beyondintochsoftware.com/site/login" role="button" target="_blank" aria-controls="offcanvasExample"><strong className="text-dark">Sign Up</strong></a>
                  </div>
                </div>
              </div>         
            </div>

                   
            <div className="home_input">
                  <strong className='text-light'                            
                    style={{             
                      letterSpacing: "-0.1em",
                      fontSize:".8em"               
                    }}
                  >FOLLOW US: </strong>
                  <span className='mx-2 px-1 pt-1' style={{backgroundColor:"#FFB527"}}>
                      <FontAwesomeIcon className="" icon={faFacebook} style={{fontSize:'18px',position:"relative"}}/>
                  </span>
                  <span className="mx-2 px-1 pt-1" style={{backgroundColor:"#FFB527"}}>
                      <FontAwesomeIcon className="" icon={faInstagram} style={{fontSize:'18px',position:"relative"}}/>
                  </span>
                  <span className="mx-2 px-1 pt-1" style={{backgroundColor:"#FFB527"}}>
                      <FontAwesomeIcon className="" icon={faTwitter} style={{fontSize:'18px',position:"relative"}} />                  
                  </span>
                
                </div>
          </div>
        </section>




        
              
        <section className='section-about' id="about"> 
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8 align-self-center text-light">
                  <h1 className='text-center '
                  style={{fontFamily:"trueno"}}
                  >ABOUT US</h1>
                  <h5 className='text-light text-center'
                  style={{letterSpacing: "0.03em",
                  lineHeight:"1em"  
                }}
                  >
                  Beyond VAS is a leading innovations and technology company<br/>
                  providing turn-key mobility solutions and platforms in the<br/>
                  education, telecom, finance, government & entertainment<br/>
                  sectors</h5>

                  
                  
                  <h5 className='text-light text-center my-5'
                  style={{letterSpacing: "0.03em"}}>
                  We are a 24/7 team of restless and curious, creative and <br/>
                  passionate people looking to reimagine what is possible <br/>
                  when technology and passion meet</h5>
              </div>
            </div>            
          </div>

          <div className='row   text-light abt_values justify-content-between mx-2 ' 
          style={{borderRadius:"25px",backgroundColor: 'rgba(255, 181, 39, 0.4)',
                overflowY:"scroll",
                height:"300px"
          }}>
              <div className='col-md-3 col-sm-12'> 
                  <div className='mx-auto col-4 py-1' style={{alignContent:"center"}}> 
                    <img src='images/our_mission.png' alt='img'
                    style={{
                      height:"2cm",
                      alignItems:"center",
                      margin:"auto"
                    }}/>
                  </div>
                  <h2 style={{fontFamily:"trueno", color:"white"}}>OUR MISSION</h2>
                  <small className='text-light'>Creating meaningfull and intuitive mobile and media solutions globally</small>
              </div>

              <div className='col-md-3 col-sm-12'> 
                <div className='mx-auto col-4 py-1' style={{alignContent:"center"}}> 
                      <img src='images/our_vision.png' alt='img'
                      style={{
                        height:"2cm",
                        alignItems:"center",
                        margin:"auto"
                      }}/>
                    </div>
                  
            
                  <h2 style={{fontFamily:"trueno", color:"white"}}>OUR VISION</h2>

                  <small className='text-light'>"To create mobile and media solutions 
                  that in turn create experiences that are meaningfull and intuitive to the world"
                  </small>
              </div>

              <div className='col-md-3 col-sm-12'> 
              <div className='mx-auto col-4 py-1' style={{alignContent:"center"}}> 
                  <img src='images/competence.png' alt='img'
                    style={{
                      height:"2cm",
                      alignItems:"center",
                      margin:"auto"
                    }}/>
                  </div>                
                 
                  <h2 style={{fontFamily:"trueno", color:"white"}}>COMPETENCE</h2>

                  <small className='text-light'>-Creativity <br/></small>
                  <small className='text-light'>-Innovation</small>
              </div>

              <div className='col-md-3 col-sm-12'> 
                   <div className='mx-auto col-4 py-1' style={{alignContent:"center"}}> 
                    <img src='images/core_values.png' alt='img'
                    style={{
                      height:"2cm",
                      alignItems:"center",
                      margin:"auto"
                    }}/>
                  </div>
                 
                  <h2 style={{fontFamily:"trueno", color:"white"}}>CORE VALUES</h2>

                  <small className='text-light'>-Intergrity <br/></small>
                  <small className='text-light'>-Leadership</small>
              </div>

            </div>
        </section>
        
        




        <section className="events-section section-bg section-padding" id="services"
         style={{ overflowY: "scroll", height: "400px" }}
        >
          <div className="container">
          <h1 className="mb-5 text-light text-center"
          style={{fontFamily:"trueno"}}
          >OUR SOLUTIONS</h1>

            <div className="row">

                <div className="col-md-3 mb-2">
                <div class="bg-light p-0 mx-auto" style={{width: "15rem",height:"27rem", borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}>
                    <img class=" w-100" src="./images/bulk_sms.jpg" alt="Card image cap" style={{borderRadius:"15px",height:"15rem"}}/>
                    <div class="card-body">
                      <h5 class="card-title text-center" style={{textDecoration:"underline"}}>BULK SMS</h5>
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>                                        
                    </div>
                  </div>
                </div>
        
                <div className="col-md-3 mb-2">
                <div class="bg-light p-0 mx-auto" style={{width: "15rem",height:"27rem", borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}>
                    <img class=" w-100" src="./images/short_code.jpg" alt="Card image cap" style={{borderRadius:"15px",height:"15rem"}}/>
                    <div class="card-body">
                      <h5 class="card-title text-center" style={{textDecoration:"underline"}}>SHORT CODE</h5>
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>                    
                    </div>
                  </div>
                </div>
                



                <div className="col-md-3 mb-2">
                <div class="bg-light p-0 mx-auto" style={{width: "15rem",height:"27rem", borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}>
                    <img class=" w-100" src="./images/ussd.jpeg" alt="Card image cap" style={{borderRadius:"15px",height:"15rem"}}/>
                    <div class="card-body">
                      <h5 class="card-title text-center" style={{textDecoration:"underline"}}>USSD</h5>
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>                   
                    </div>
                  </div>
                </div>


                <div className="col-md-3 mb-2">
                <div class="bg-light p-0 mx-auto" style={{width: "15rem",height:"27rem", borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}>
                    <img class=" w-100" src="./images/loyalty.jpg" alt="Card image cap" style={{borderRadius:"15px",height:"15rem"}}/>
                    <div class="card-body">
                      <h5 class="card-title text-center" style={{textDecoration:"underline"}}>LOYALTY</h5>
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>
                  </div>
                </div>


              </div>

            <div className="row mt-5">

              <div className="col-md-3 mb-2">
              <div class="bg-light p-0 mx-auto" style={{width: "15rem",height:"27rem", borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}>
                  <img class=" w-100" src="./images/pos.jpg" alt="Card image cap" style={{borderRadius:"15px",height:"15rem"}}/>
                  <div class="card-body">
                    <h5 class="card-title text-center" style={{textDecoration:"underline"}}>POS</h5>
                    <p class="card-text">Create the intersection between sales, inventory management , payment processing and customer management (Online & Oflline)</p>                                        
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-2">
              <div class="bg-light p-0 mx-auto" style={{width: "15rem",height:"27rem", borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}>
                  <img class=" w-100" src="./images/api.jpg" alt="Card image cap" style={{borderRadius:"15px",height:"15rem"}}/>
                  <div class="card-body">
                    <h5 class="card-title text-center" style={{textDecoration:"underline"}}>API's</h5>
                    <p class="card-text">Use your API to interact with your clients.</p>                    
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-2">
              <div class="bg-light p-0 mx-auto" style={{width: "15rem",height:"27rem", borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}>
                  <img class=" w-100" src="./images/whatsapp_marketing.jpg" alt="Card image cap" style={{borderRadius:"15px",height:"15rem"}}/>
                  <div class="card-body">
                    <h5 class="card-title text-center" style={{textDecoration:"underline"}}>WHATSAPP</h5>
                    <p class="card-text">Communicate with customers through the most downloaded App in the world using messages, pictures, videos, audios & many more</p>                   
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-2 ">
              <div class="bg-light p-0 mx-auto" style={{width: "15rem",height:"27rem", borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}>
                  <img class=" w-100" src="./images/NCP.jpg" alt="Card image cap" style={{borderRadius:"15px",height:"15xrem"}}/>
                  <div class="card-body">
                    <h5 class="card-title text-center" style={{textDecoration:"underline"}}>NCP</h5>
                    <p class="card-text">End to end solutions for National Consumer Promotional Campaign</p>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </section>


    


        {/* <section className="events-section section-bg section-padding" id="pricing" style={{backgroundColor:"#F2CC8F"}}>
          <div className="container">
            <div className="row">              
              <h1 className="mb-lg-3 d-flex text-center text-dark" style={{fontSize:"1.5cm"}}><strong>OUR PRICING</strong></h1>
                                      
              <div className="col-md-12">
                <h3 className="text-light">Surveys</h3>
                <div className="table-responsive">
                  <table className="table table-bordered shadow p-3 mb-5 bg-white rounded">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Telco</th>
                        <th scope="col">Deposit</th>
                        <th scope="col">Set Up</th>
                        <th scope="col">Monthly Maintenance/Telco</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Safaricom</td>
                        <td>KES 10,000</td>
                        <td>Free</td>
                        <td>KES 15,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
                                
              <div className="col-md-12">
                <h3 className="text-light">Loyalty Programms | Rewards</h3>
                <div className="table-responsive">
                  <table className="table table-bordered shadow bg-white rounded">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Telco</th>
                        <th scope="col">Deposit</th>
                        <th scope="col">Set Up</th>
                        <th scope="col">Monthly Maintenance/Telco</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Safaricom</td>
                        <td>KES 10,000</td>
                        <td>Free</td>
                        <td>KES 15,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
                                   
              <div className="col-md-12">
                <h3 className="text-light">Payments</h3>
                <div className="table-responsive">
                  <table className="table table-bordered shadow bg-white rounded">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Telco</th>
                        <th scope="col">Deposit</th>
                        <th scope="col">Set Up</th>
                        <th scope="col">Monthly Maintenance/Telco</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Safaricom</td>
                        <td>KES 10,000</td>
                        <td>Free</td>
                        <td>KES 15,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
                                          
              <div className="col-md-12">
                <h3 className="text-light">USSD</h3>
                <div className="table-responsive">
                  <table className="table table-bordered shadow bg-white rounded">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Telco</th>
                        <th scope="col">Deposit</th>
                        <th scope="col">Set Up</th>
                        <th scope="col">Monthly Maintenance/Telco</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Safaricom</td>
                        <td>KES 10,000</td>
                        <td>Free</td>
                        <td>KES 15,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
               
            </div>
          </div>
        </section> */}


        {/* <section className="section-bg-image">
          <svg viewBox="0 0 1265 144"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path fill="rgba(255, 255, 255, 1)" d="M 0 40 C 164 40 164 20 328 20 L 328 20 L 328 0 L 0 0 Z" stroke-width="0" ></path>{" "}
            <path fill="rgba(255, 255, 255, 1)" d="M 327 20 C 445.5 20 445.5 89 564 89 L 564 89 L 564 0 L 327 0 Z" stroke-width="0" ></path>{" "}
            <path fill="rgba(255, 255, 255, 1)" d="M 563 89 C 724.5 89 724.5 48 886 48 L 886 48 L 886 0 L 563 0 Z" stroke-width="0" ></path>
            <path fill="rgba(255, 255, 255, 1)" d="M 885 48 C 1006.5 48 1006.5 67 1128 67 L 1128 67 L 1128 0 L 885 0 Z" stroke-width="0"></path>
            <path fill="rgba(255, 255, 255, 1)" d="M 1127 67 C 1196 67 1196 0 1265 0 L 1265 0 L 1265 0 L 1127 0 Z" stroke-width="0" ></path>
          </svg>

          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="section-bg-image-block">
                  <h2 className="mb-lg-3">Beyond API</h2>

                  <p>
                    Use our APIs with your application or services to build a
                    robust system that connects with your customers
                  </p>

                  <form action="#" method="get" className="custom-form mt-lg-4 mt-2" role="form">
                    <div className=" bg-dark text-light">
                      <div className="container ">
                        <div>
                          <div>
                            <pre>
                              {`   
var settings = {
  "async": true,
  "url": "https://api.beyondintochsoftware.com/services/PushSMS",
  "method": "POST",
  "headers": {
      "cache-control": "no-cache",
      "Content-type": "application/json"
  },
  "data": {
      "short_code": "BEYONDSMS",
      "client_code": "beyondsms",
      "key": "api token here",
      "message": "This is a test message",
      "recipients": [
          "07xxxxxxxx",
          "07xxxxxxx"
      ],
      "call_back": "http://your_call_back_url"
  }
}

$.ajax(settings).done(function (response) {
  console.log(response);
});                                                
`}
                            </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <svg viewBox="0 0 1265 144" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path fill="rgba(255, 255, 255, 1)" d="M 0 40 C 164 40 164 20 328 20 L 328 20 L 328 0 L 0 0 Z" stroke-width="0"></path>{" "}
            <path fill="rgba(255, 255, 255, 1)" d="M 327 20 C 445.5 20 445.5 89 564 89 L 564 89 L 564 0 L 327 0 Z" stroke-width="0"></path>{" "}
            <path fill="rgba(255, 255, 255, 1)" d="M 563 89 C 724.5 89 724.5 48 886 48 L 886 48 L 886 0 L 563 0 Z" stroke-width="0"></path>
            <path fill="rgba(255, 255, 255, 1)" d="M 885 48 C 1006.5 48 1006.5 67 1128 67 L 1128 67 L 1128 0 L 885 0 Z" stroke-width="0" ></path>
            <path fill="rgba(255, 255, 255, 1)" d="M 1127 67 C 1196 67 1196 0 1265 0 L 1265 0 L 1265 0 L 1127 0 Z" stroke-width="0"></path>
          </svg>
        </section> */}


        <section class="membership-section section-padding" id="clients">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-12 text-center mx-auto ">
                <h1 className='text-light'
                style={{fontFamily:"trueno"}}
                >CLIENTS & PARTNERS</h1>
              </div>

              <div className="d-flex justify-content-center mx-auto">
                <ul
                  className="list-unstyled d-flex justify-content-center align-items-center flex-wrap"
                  style={{ paddingLeft: "0" }}
                >

                <li className=" col-12  text-center"
                style={{
                  marginBottom:"-2cm"
                }}>
                    <img
                      src="./images/takepay.PNG"
                      alt="takepay"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "center", 
                        borderRadius: "600px",
                        
                      }}
                    />
                  </li>


                  <li className="px-1 ">
                    <img
                      src="./images/safaricom.png"
                      alt="Image 2"
                      style={{
                        width: "300px",                        
                        borderRadius: "10px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                  </li>

                  <li className="px-1 ">
                    <img
                      src="./images/starpic.jpeg"
                      alt="Image 2"
                      style={{
                        width: "4cm",
                        height: "2cm",
                        borderRadius: "600px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                  </li>


                 

                  <li className=""
                    style={{
                      marginLeft:"3cm"
                    }}
                  >
                    <img
                      src="./images/cedar.jpeg"
                      alt="Image 4"
                      style={{
                        width: "200px",
                        height: "200px",
                        padding: "10px",
                        borderRadius: "50%",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                        objectFit: "cover",
                      }}
                    />
                  </li>

                

                  <li className="">
                    <img
                      src="./images/airtel.png"
                      alt="Image 2"
                      style={{
                        width: "300px",
                        height: "100px",
                        borderRadius: "10px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                  </li>


                  <li className="col-12 text-center"
                    style={{
                      marginTop:"-2cm"
                    }}
                  >
                    <img
                      src="./images/logo.png"
                      alt="quicksava"
                      style={{
                        width: "200px",
                        height: "80px",
                        padding: "10px",
                        objectFit: "center",
                        // filter: "drop-shadow(0px 5px 5px black)"
                      }}
                      />
                  </li>



                  <li className="">
                    <div className="bg-light p-5"
                    style={{
                      borderRadius:"50%",
                      height:"5cm",
                      width:"5cm",
                      marginTop:"-2cm"
                    }}
                    >
                    <img
                      src="./images/quicksava.png"
                      alt="betnare"
                      style={{
                        width: "130px",
                        height: "70px",                     
                        backgroundColor:"white",    
                        marginTop:".4cm",
                        marginLeft:"-.4cm"
                      }}                      
                    />

                    </div>
                  </li>

                
           
                  <li className="">
                    <div className="bg-light p-5"
                    style={{
                      borderRadius:"50%",
                      height:"5cm",
                      width:"5cm",
                      marginTop:"-2cm",
                      marginLeft:"6cm"
                    }}
                    >
                    <img
                      src="https://storage.googleapis.com/nareimages/logo-dark.webp"
                      alt="betnare"
                      style={{
                        width: "65",
                        height: "20px",                     
                        backgroundColor:"white",    
                        marginTop:".6cm",
                        marginLeft:"-.4cm"
                      }}                      
                    />

                    </div>
                  </li>




                 

                  <li className="col-12 text-center"
                    style={{
                      marginTop:"-1cm"
                    }}
                  >
                    <img
                      src="./images/telcom.png"
                      alt="Image 2"
                      style={{
                        width: "300px",
                        height: "250",
                        borderRadius: "10px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>



        <section className="testimonials-section section-bg section-padding" id="testimonials">
          <div className="container" >
          <h1 className="mb-5  text-light text-center"
          style={{fontFamily:"trueno"}}
          ><strong>CLIENT TESTIMONIALS</strong></h1>

            <div className="row justify-content-between" >

              <div className="col-md-4">
              <div class="card mt-5 mx-auto p-0" style={{width: "20rem",borderRadius:"25px",lineHeight:"1rem",height:"20rem"}}>
                  <div class="card-body">
                    <h1 class="card-title text-center"><strong
                    style={{fontFamily:"trueno", fontSize:".7cm"}}
                    >BETNARE</strong></h1>
                    <p class="card-text text-center text-dark">"Considering the demand in constant communication with our Punters, Beyond VAS have given us an easy way to reach millions of our customers with the click of a button in seconds. They have grown with our brand every step of the way and helped drive our bussiness towards customer success through a great customer journey providing us with fast and efficent services through their Platform"</p>
                  </div>
                </div>
              </div>


              <div className="col-md-4">
              <div class="card mx-auto p-0 " style={{width: "22rem", borderRadius:"25px",lineHeight:"1.2rem",height:"25rem"}}>
                  <div class="card-body">
                    <h1 class="card-title text-center mt-4"
                    style={{fontFamily:"trueno", fontSize:".7cm"}}                    
                    >QUICK SAVA</h1>
                    <p class="card-text text-center text-dark">"Beyond VAS Solution Limited has been a long-standing partner for QuickSava which consume their Bill payment, Airtime and USSD services. They have excellent customer service through a dedicated relatioship manager, timely dispute resolution by their support team, and 99% uptime for the services they provide. We are happy with their services and will continue to have them as an intergral partner</p>
                  </div>
                </div>
              </div>


              <div className="col-md-4">
              <div class="card mt-5 mx-auto p-0" style={{width: "20rem",borderRadius:"25px",lineHeight:"1rem",height:"20rem"}}>
                  <div class="card-body">
                    <h1 class="card-title text-center"
                    style={{fontFamily:"trueno", fontSize:".7cm"}}                    
                    >CEDAR CLINK</h1>
                    <p class="card-text text-center text-dark">"Beyond VAS has been a partner of ours for the past 2 years through this period, they have been a reliable partner, assisting us to manage services that are really not a core part of our bussiness. We are confident that they will continue to offer us top notch IT consulting services</p>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>


        <section className="testimonials-section section-bg section-padding" id="contactus">
          <div className="container bg-light" >
     

            <div className="row justify-content-between p-5" >
              <div className="col-md-6">
                <h1 className="mb-5 text-center"
                  style={{fontFamily:"trueno"}}
                  ><strong>Get in Touch</strong></h1>

                  <div className="col-12 row justify-content-end my-5">
                    <div className="col-4">
                    <span className="" style={{Color:"#FFB527", borderRadius:"55px"}}>
                        <FontAwesomeIcon className="" color="#FFB527" icon={faPhone} style={{fontSize:'38px',position:"relative"}} />                  
                    </span>
                    </div>
                    <div className="col-8">
                      Call Us<br/>
                      <strong>+254712345678</strong>
                    </div>                   
                  </div>


                  <div className="col-12 row justify-content-end my-5">
                    <div className="col-4">                    
                      <span className="" style={{Color:"#FFB527", borderRadius:"55px"}}>
                        <FontAwesomeIcon className="" color="#FFB527" icon={faMailBulk} style={{fontSize:'38px',position:"relative"}} />                  
                    </span>
                    </div>
                    <div className="col-8">
                      Email Us<br/>
                      <strong>+254712345678</strong>
                    </div>                   
                  </div>


                  <div className="col-12 row justify-content-end my-5">
                    <div className="col-4">                      
                      <span className="" style={{Color:"#FFB527", borderRadius:"55px"}}>
                        <FontAwesomeIcon className="" color="#FFB527" icon={faMapLocation} style={{fontSize:'38px',position:"relative"}} />                  
                      </span>
                    </div>
                    <div className="col-8">
                      Find Us<br/>
                      <strong>+254712345678</strong>
                    </div>                   
                  </div>
                  
           

              </div>

              <div className="col-md-6 "
                  style={{
                    background:"#cccccc",
                borderRadius:"25px"
              }}
              >
                  <h1 className="mb-5   text-center"
                  style={{fontFamily:"trueno"}}
                  ><strong>Contact Us</strong></h1>

                  <form action="#" method="post" className="custom-form contact-form" role="form"
                    onSubmit={handleSubmit}
                    >
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="form-floating">
                                    <input type="text" name="full-name" id="full-name" className="form-control" placeholder="Full Name" required=""/>
                                    
                                    <label for="floatingInput">Full Name</label>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-12"> 
                                <div className="form-floating">
                                    <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" className="form-control" placeholder="Email address" required=""/>
                                    
                                    <label for="floatingInput">Email address</label>
                                </div>
                            </div>

                            <div className="col-lg-12 col-12">
                                <div className="form-floating">
                                    <textarea className="form-control" id="message" name="message" placeholder="Describe message here"></textarea>
                                    
                                    <label for="floatingTextarea">Message</label>
                                </div>

                                <button type="submit" className="btn  w-100" style={{backgroundColor:"FFB527"}}>Submit Form</button>
                            </div>
                        </div>
                    </form>

              </div>

    
            </div>


          </div>
        </section>        
      </main>
      </>
        
    )
}

export default Home;