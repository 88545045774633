import { Link } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram,faTwitter} from "@fortawesome/free-brands-svg-icons"


const Navbar = () => {
    return (
        <div id="sticky-wraper is-sticky" className="sticky-wrapper " style={{ height: "171px", zIndex: "1000" }}>
            <nav className="navbar navbar-expand-sm" style={{width: "100%", position: "fixed",
            
        }}>

                <div className="container">
                    <Link to="/" className=" d-flex ">
                        <img src="images/logo.png" className="" alt="logo" style={{ width: "4cm" }}/>
                    </Link>

                    <button className="navbar-toggler bg-success" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="text-light">M</span>

                        <span className="">e</span>
                        <span className=""></span>

                    </button>

                    <div className="collapse navbar-collapse " id="navbarNav">
                        <ul className="navbar-nav ms-lg-auto mx-auto">
                            <li className="nav-item me-3">
                                <a className="nav-link click-scroll" href="#hero-section">Home</a>
                            </li>

                            <li className="nav-item me-3">
                                <a className="nav-link click-scroll" href="#about">About</a>
                            </li>

                            <li className="nav-item me-3">
                                <a className="nav-link click-scroll" href="#services">Services</a>
                            </li>


                            <li className="nav-item me-3">
                                <a className="nav-link click-scroll" href="#clients">Clients</a>
                            </li>


                            <li className="nav-item me-3">
                                <a className="nav-link click-scroll" href="#testimonials">Testimonials </a>
                            </li>

                            <li className="nav-item me-3">
                            <a className="nav-link click-scroll" href="#contactus">Contact</a>
                        </li>


                        </ul>

                        <div className="">
                            <a className="px-4 " style={{ backgroundColor: "#FFB527", border: "none", fontFamily:"trueno" }} href="https://beyondsms.beyondintochsoftware.com/site/login" role="button" target="_blank" aria-controls="offcanvasExample"><strong className="text-dark">Login</strong></a>
                        </div>

                        <div className="d-none d-lg-block ms-lg-3">
  

                       <a href="https://beyondsms.beyondintochsoftware.com/site/login">
                        <span className="px-2 py-1 mx-2 my-4" style={{backgroundColor:"grey",borderRadius:"55px"}}>
                            <FontAwesomeIcon className="" icon={faUser} style={{fontSize:'15px',position:"relative"}} 
                            color="white"
                            />                  
                        </span>  
                        </a>              

                        </div>


                        {/* <div className="d-none d-lg-block ms-lg-3">
                        <a className="btn custom-btn custom-border-btn" style={{backgroundColor:"var(--custom-btn-bg-hover-color)", border:"none"}} href="https://beyondsms.beyondintochsoftware.com/site/signup" target="_blank" role="button" aria-controls="offcanvasExample"><span className="text-light">Register</span></a>
                    </div> */}
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar;