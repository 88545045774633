import {Link} from 'react-router-dom';
import React, { useEffect } from 'react';

const ShortCodes = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
      <div className="shortcodes">
          <div id="sticky-wraper is-sticky" className="sticky-wrapper" style={{height:"171px"}}>
              <nav className="navbar navbar-expand-lg" style={{width:"100%",position:"fixed",backgroundColor:"#FFB527"}}>                
                  <div className="container">
                      <Link to="/" className="navbar-brand d-flex align-items-center" >
                          <img src="images/logo.webp" className="navbar-brand-image img-fluid" alt="Tiya Golf Club"/>
                          <span className="navbar-brand-text">Beyond<small>In Toch</small></span></Link>
                      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                          <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="collapse navbar-collapse" id="navbarNav">
                          <ul className="navbar-nav ms-lg-auto">
                              <li className="nav-item"><Link to="/" className="nav-link click-scroll">Home</Link>
                              </li>        
                          </ul>
                          <div className="d-none d-lg-block ms-lg-3">
                              <a className="btn custom-btn custom-border-btn" href="https://beyondsms.beyondintochsoftware.com/site/login" target="_blank" role="button" aria-controls="offcanvasExample"><span className="text-light">Login</span></a>
                          </div>
                          <div className="d-none d-lg-block ms-lg-3">
                              <a className="btn custom-btn custom-border-btn" href="https://beyondsms.beyondintochsoftware.com/site/signup" target="_blank"  ><span className="text-light">Register Test</span></a>
                          </div>
                      </div>
                  </div>
              </nav>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
            fill='#FFB527'
              fill-opacity="1"
              d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
            ></path>
          </svg>
          </div>
      
  
          <div className="body">
              
          <section className="section-padding" >
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-12 col-12">
                              <h2 className="mb-lg-5" style={{marginTop:"4em"}}>Beyond Short Codes</h2>
                          </div>
  
  
                          <div className="col-lg-12 col-12 mb-5 mb-lg-0">
                              <h3 className="text-muted">Captivate your customers with customized,<br/> precise communications tailored to their preferences <br/>and individual interests.</h3>
                          </div>
  
                         
                          <div className="col-lg-12 col-12 mb-5 mb-lg-0">
                            <a className="btn custom-btn custom-border-btn mt-3" data-bs-toggle="offcanvas" href="#offcanvasRegister" role="button" aria-controls="offcanvasExample" style={{ backgroundColor: "#FFB527" , border:"none"}}>
                                <span className="text-light h4 mt-5">Sign Up</span>
                            </a>
                        </div>
  
                          <hr className="mt-5"/>
  
                          <div className="col-lg-6 col-12 mb-5 mb-lg-0">
                              <div className="custom-block-info">
                                  <a href="event-detail.html" className="events-title mb-2">Dedicated Short Code</a>
                                  <p className="mb-0">This shortcode is owned by you, the client. The client decides on the price to charge on the shortcode.</p>
                              </div>
                          </div>
  
                          <div className="col-lg-6 col-12">
                              <div className="custom-block-info">
                                  <a href="event-detail.html" className="events-title mb-2">Shared Short Code</a>
                                  <p className="mb-0">This shortcode is used by multiple users and differentiated by a keyword. You do not pay for the shortcode aquisition or maintenance, but a one-off fee for the keywords is applied.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          
          {/* pricing */}
          <section className="pb-5 mb-5">
                  <div className="container">
                      <div className="row">
                        
  
                          <div className="col-lg-4   col-12 mb-5 mb-lg-0 ">
                              <div className="custom-block-info">
                                  <h1 className=""  style={{color:"#FFB527"}}>Pricing</h1>
                              </div>
  
                              <div className="custom-block-info">
                                  <h4 className="text-muted">All prices are exclusive of VAT. Prices are in Kenyan Shillings.</h4>
                              </div>


                            <a className="btn custom-btn custom-border-btn mt-3"  href="https://beyondsms.beyondintochsoftware.com/site/signup" target='_blank' role="button" aria-controls="offcanvasExample" style={{ backgroundColor: "#FFB527" , border:"none"}}>
                                <span className="text-light h5 mt-5">Sign Up</span>
                            </a>
  
                          </div>
  
                          <div className="col-lg-4 col-12 d-flex justify-content-center">
                              <div className="custom-block-info">
                              <div class="card">
                              <div class="card-content">
                                  <h2 class="card-title text-center">Dedicated Short Code</h2>
                                  <hr/>
                                  <ul class="pricing-list">
                                  <li><strong>Setup Fee:</strong> Applies to Safaricom and Airtel MNOs - FREE</li>
                                  <hr/>
                                  <li><strong>Monthly Maintenance:</strong> KES. 5,000.00 each MNO</li>
                                  <hr/>
                                  <li><strong>Keywords:</strong> One-off fee - KES. 3,000.00</li>
                                  </ul>
                              </div>
                              </div>
                              </div>
                          </div>
  
                          <div className="col-lg-4 col-12 d-flex justify-content-center">
                              <div className="custom-block-info">
                              <div class="card">
                              <div class="card-content">
                                  <h2 class="card-title text-center">Shared shortCode</h2>
                                  <hr/>
                                  <ul class="pricing-list">
                                  <li><strong>Setup Fee:</strong> Applies to Safaricom and Airtel MNOs - FREE</li>
                                  <hr/>
                                  <li><strong>Monthly Maintenance:</strong> KES. 5,000.00 each MNO</li>
                                  <hr/>
                                  <li><strong>Keywords:</strong> One-off fee - KES. 3,000.00</li>
                                  </ul>
                              </div>
                              </div>
                              </div>
                          </div>
                          
                      </div>
                  </div>
              </section>
          </div>
      </div>
    );
  };
  
  export default ShortCodes;
  